import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { DestroyableDirective } from '../destroyable/destroyable.directive';
import { takeUntil } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOptionModel } from '@lms/shared/models';
import { noop } from 'rxjs';

@Component({
  selector: 'app-raw-select',
  templateUrl: './raw-select.component.html',
  styleUrls: ['./raw-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RawSelectComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class RawSelectComponent extends DestroyableDirective implements ControlValueAccessor, AfterViewInit {
  @Input() options: SelectOptionModel[] = [];
  isTablet = false;
  val!: SelectOptionModel;

  set value(val: SelectOptionModel) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
    }
  }

  private onChange = (value: SelectOptionModel): void => noop();
  private onTouched = (): void => noop();

  constructor(
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: SelectOptionModel): void {
    this.value = obj;
  }

  ngAfterViewInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 992px)'])
      .pipe(takeUntil(this._destroy$$))
      .subscribe(mediaResponsive => {
        this.isTablet = mediaResponsive.matches;
        this.cdr.detectChanges();
      });
  }
}
