import { inject, Injectable } from '@angular/core';
import { CurrentUserStorage } from './current-user.storage';
import { B2cUuid, BundleLink } from '@lms/shared/models';

@Injectable({ providedIn: 'root' })
export class B2bSearchMappingService {
  currentUser = inject(CurrentUserStorage);
  excludedB2cUuids: string[] = [];
  b2cToB2bMap: Record<B2cUuid, BundleLink> = {};

  getEnrolledBundleLinks() {
    const user = this.currentUser.getCurrentUser();
    if (!user) return;
    this.b2cToB2bMap = {};
    this.excludedB2cUuids = [];
    const enrolled = user.enrolledBundles;
    const bundleLinks: BundleLink[] = user.bundleLinks;

    bundleLinks.forEach(link => {
      this.b2cToB2bMap[link.B2CBundleUuid] = link;
      if (link.B2BBundleId === null && link.B2CBundleId && !enrolled.includes(link.B2CBundleId)) {
        this.excludedB2cUuids.push(link.B2CBundleUuid);
      }
    });
  }
}
