import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import {
  ApiResponseModel,
  Environment,
  ENVIRONMENT_TOKEN,
  Template,
  TemplateListingAbstractListingModel,
  TemplateListingFilterModel,
  TemplateListingModel,
  TemplateResponseModel,
} from '@lms/shared/models';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TemplateService {
  constructor(
    private apiService: ApiService,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
  ) {}

  listing(filter: TemplateListingFilterModel): Observable<ApiResponseModel<TemplateListingAbstractListingModel>> {
    let params = new HttpParams()
      .set('pageNumber', filter.pageNumber?.toString() || '')
      .set('sort', filter.sort?.toString() || '')
      .set('pageSize', filter.pageSize?.toString() || '');
    if (typeof filter.query !== 'undefined' && filter.query !== null) {
      params = params.set('query', filter.query.toString());
    }
    if (typeof filter.level !== 'undefined' && filter.level !== null) {
      params = params.set('level', filter.level?.toString());
    }
    if (typeof filter.price !== 'undefined' && filter.price !== null) {
      params = params.set('plan', filter.price);
    }
    if (typeof filter.category !== 'undefined' && filter.category !== null) {
      params = params.set('topic', filter.category?.toString());
    }

    return this.apiService
      .executeGet<ApiResponseModel<TemplateListingModel>>('templates', params)
      .pipe(map((response: ApiResponseModel<TemplateListingModel>) => this.mapTemplateListingModel(response)));
  }

  getEntity(uuid: string): Observable<Template> {
    return this.apiService.executeGet<ApiResponseModel<TemplateResponseModel>>('templates/' + uuid).pipe(map(res => res.data.template));
  }

  private mapTemplateListingModel(response: ApiResponseModel<TemplateListingModel>): ApiResponseModel<TemplateListingAbstractListingModel> {
    return {
      status: response.status,
      data: {
        ...response.data,
        itemsList: response.data.templates,
      },
    };
  }
}
