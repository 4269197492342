import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';

@Pipe({
  name: 'dashboardDomain',
  standalone: false,
})
export class DashboardDomainPipe implements PipeTransform {
  constructor(@Inject(ENVIRONMENT_TOKEN) private environment: Environment) {}
  transform(uri: string): URL {
    return new URL(uri, this.environment.dashboardPanelUrl);
  }
}
