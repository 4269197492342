import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ButtonComponent {
  BUTTON_STATE = BUTTON_STATE;

  @Input() state: BUTTON_STATE | null = BUTTON_STATE.STANDBY;
  @Input() type: string = 'submit';
  @Input() title: string = '';
  @Input() class: string = '';
  @Input() isDisabled!: boolean;
}

export enum BUTTON_STATE {
  STANDBY = 'standby',
  STARTING = 'starting',
  LOADING = 'loading',
  DONE = 'done',
  DISABLED = 'disabled',
}
