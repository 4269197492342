import { Pipe, PipeTransform } from '@angular/core';

type trademarksOptions = {
  [key: string]: string;
};

const TRADEMARKS_MAP: trademarksOptions = {
  fmva: '\u00AE',
  'fmva-room': '\u00AE',
  cbca: '\u00AE',
  bida: '\u00AE',
  cmsa: '\u00AE',
  fpwm: '\u2122',
  ftip: '\u2122',
};

@Pipe({
  name: 'trademark',
  standalone: false,
})
export class TrademarkPipe implements PipeTransform {
  transform(slug: string | null): string {
    if (slug && TRADEMARKS_MAP[slug.toLowerCase()]) {
      return TRADEMARKS_MAP[slug.toLowerCase()];
    }
    return '';
  }
}
