import { Pipe, PipeTransform } from '@angular/core';
import { PriceModel } from '@lms/shared/models';

@Pipe({
  name: 'price',
  standalone: false,
})
export class PricePipe implements PipeTransform {
  public transform(price: undefined | PriceModel | null, defaultValue = '', discount = 0): string {
    if (!price || price.amount === null) {
      return defaultValue;
    }
    const amountParts = this.applyDiscountOnAmount(price.amount, discount).toString().split('.');
    const amount = `${amountParts[0]}.${(amountParts[1] || '').padEnd(2, '0')}`;
    const currenciesMap = {
      USD: '$',
      EUR: '€',
      INR: '₹',
    } as any;

    if (!currenciesMap[price.currency]) {
      return `${amount} ${price.currency}`;
    }

    return `${currenciesMap[price.currency]}${amount}`;
  }

  applyDiscountOnAmount(amount: number, discount: number): number {
    return discount ? +(amount * (1 - discount / 100)).toFixed(2) : amount;
  }
}
