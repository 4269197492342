import { Injectable } from '@angular/core';
import { CourseModel, PricingPlanEnum, PricingPlanFlowEnum, PricingPlanModel } from '@lms/shared/models';
import { Router } from '@angular/router';
import { NotifierService } from './notifier.service';

@Injectable({ providedIn: 'root' })
export class PricingPlanFlowService {
  constructor(
    private router: Router,
    private notifierService: NotifierService,
  ) {}

  public redirect(
    pricingPlan: PricingPlanModel,
    isCourse?: boolean,
    courseModel?: CourseModel,
    isManualVerification?: boolean,
    publicUrl?: string | null | undefined,
  ): void {
    let url = this.router.url.split('/');
    if (courseModel?.url) {
      url = ['course', courseModel.url, 'enroll', 'free', 'confirm'];
    }
    switch (pricingPlan.afterPurchaseFlow.endpoint) {
      case PricingPlanFlowEnum.COURSE_PLAYER:
        this.notifierService.success(`You are now enrolled for this ${isCourse ? 'course' : 'program'}`);
        this.router.navigate(['/courses/take', pricingPlan.afterPurchaseFlow.endpointUrl]);
        break;
      case PricingPlanFlowEnum.CUSTOM_URL:
        if (!pricingPlan.afterPurchaseFlow.endpointUrl) {
          break;
        }
        this.router.navigateByUrl('/redirect?url=' + pricingPlan.afterPurchaseFlow.endpointUrl);
        break;
      case PricingPlanFlowEnum.THANK_YOU_PAGE:
        url.pop();
        isManualVerification ? url.push(`success?isManualVerification=true&publicUrl=${publicUrl}`) : url.push('success');
        this.router.navigateByUrl(url.join('/'));
        break;
      default:
        break;
    }
  }

  public isCheckoutAllowed(pricingPlan: PricingPlanModel | null): boolean {
    if (PricingPlanEnum.SUBSCRIPTION === pricingPlan?.plan && !!pricingPlan?.freeTrialPeriod) {
      return false;
    }
    const isAllowedPricingPlan = PricingPlanEnum.ONE_TIME === pricingPlan?.plan || PricingPlanEnum.SUBSCRIPTION === pricingPlan?.plan;
    return isAllowedPricingPlan && pricingPlan?.prices?.discounted?.totalPrice?.amount !== 0;
  }
}
