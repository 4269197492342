import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idToProgram',
  standalone: false,
})
export class IdToProgramPipe implements PipeTransform {
  transform(value: string, programs: any[]): unknown {
    return programs?.find(el => +el.id === +value)?.shortName;
  }
}
