import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryService } from '@lms/shared/services/api-services';
import { DictionaryEnum, DictionaryEnumModel, EnumsDictionaryModel } from '@lms/shared/models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'translateEnum',
  standalone: false,
})
export class TranslateEnumPipe implements PipeTransform {
  constructor(private dictionaryService: DictionaryService) {}

  transform(value: number | string, dictionaryType: DictionaryEnum): Observable<null | string> {
    return this.dictionaryService.getEnumsDictionary().pipe(map(this.getSpecificValue(value, dictionaryType)));
  }

  private getSpecificValue(value: number | string, enumType: string): (value: any, index: number) => string | null {
    return (data): string | null =>
      data[enumType as keyof EnumsDictionaryModel]?.find((dictionaryEnum: DictionaryEnumModel) => dictionaryEnum.value === value)?.label ||
      null;
  }
}
