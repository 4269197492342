import { Component, ChangeDetectionStrategy, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'app-star-rating-select',
  templateUrl: './star-rating-select.component.html',
  styleUrls: ['./star-rating-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarRatingSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StarRatingSelectComponent implements ControlValueAccessor {
  @Input() value: number = 0;

  starList = [1, 2, 3, 4, 5];

  selectValue(value: number): void {
    this.value = value;
    this.writeValue(value);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: number): void {
    this.value = obj;
    this.onChange(obj);
  }

  private onChange = (value: number): void => noop();

  private onTouched = (): void => noop();
}
