import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface UserStoryData {
  title: string;
  content: string;
  fullName: string;
  jobPosition: string;
  linkOnMore: string;
  image: string;
}

@Component({
  selector: 'app-user-story-card',
  templateUrl: './user-story-card.component.html',
  styleUrls: ['./user-story-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UserStoryCardComponent {
  @Input() userStoryData: UserStoryData | undefined;
}
