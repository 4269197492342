import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDiff',
  standalone: false,
})
export class TimeDiffPipe implements PipeTransform {
  transform(value: string | null | undefined): string | null {
    if (value) {
      const seconds = Math.floor((+new Date(value) - +new Date()) / 1000);
      const intervals: any = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i; // singular (1 day)
          } else {
            return counter + ' ' + i + 's'; // plural (2 days)
          }
      }
    }
    return null;
  }
}
