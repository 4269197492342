import { Injectable } from '@angular/core';
import { Routing } from '@lms/shared/constants';
import { BundleTypeEnum } from '@lms/shared/models';

@Injectable({ providedIn: 'root' })
export class BuildBundleLinkService {
  learningPathUrl = Routing.LEARNING_PATH;

  public buildLinkToBundleDetailedPage(url: string, bundleType: string): string {
    if (bundleType === BundleTypeEnum.SPECIALIZATION_TYPE) {
      return `specializations/${url}`;
    }

    if (bundleType === BundleTypeEnum.BUNDLE_TYPE) {
      return `${this.learningPathUrl}/${url}`;
    }

    if (bundleType === BundleTypeEnum.LEARNING_PATH_TYPE) {
      return `learning-path/${url}`;
    }

    return `${url}`;
  }
}
