import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { LessonContentTypeEnum } from '@lms/shared/models';

@Component({
  selector: 'app-lesson-content-type-icon',
  templateUrl: './lesson-content-type-icon.component.html',
  styleUrls: ['./lesson-content-type-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LessonContentTypeIconComponent {
  @Input()
  public lessonContentType: LessonContentTypeEnum = LessonContentTypeEnum.TEXT;

  private readonly typesMap: Record<LessonContentTypeEnum, string> = {
    [LessonContentTypeEnum.PDF]: 'course_pdf_b',
    [LessonContentTypeEnum.TEXT]: 'course_text_b',
    [LessonContentTypeEnum.VIDEO]: 'course_video_b',
    [LessonContentTypeEnum.AUDIO]: 'course_audio_b',
    [LessonContentTypeEnum.DOWNLOAD]: 'course_download_b',
    [LessonContentTypeEnum.MULTIMEDIA_EXTERNAL_URL]: 'course_multimedia_b',
    [LessonContentTypeEnum.MULTIMEDIA_ZIP]: 'course_multimedia_b',
    [LessonContentTypeEnum.MULTIMEDIA_ZIP_URL]: 'course_multimedia_b',
    [LessonContentTypeEnum.EXAM]: 'course_exam_b',
    [LessonContentTypeEnum.QUIZ]: 'course_quiz_b',
  };

  get icon(): string {
    return this.typesMap[this.lessonContentType];
  }
}
