import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CurrentUserStorage } from '@lms/shared/storages';
import { UserModel } from '@lms/shared/models';

@Component({
  selector: 'app-unlock-community-dialog',
  templateUrl: './unlock-community-dialog.component.html',
  styleUrls: ['./unlock-community-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UnlockCommunityDialogComponent implements OnInit {
  user: UserModel | null = null;
  constructor(private currentUserStorage: CurrentUserStorage) {}

  ngOnInit(): void {
    this.user = this.currentUserStorage.getCurrentUser();
  }
}
