import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '@lms/shared/models';
import { ThirdPartyLoaderService } from './third-party-loader.service';

@Injectable({
  providedIn: 'root',
})
export class GtmContestService {
  private thirdParty = inject(ThirdPartyLoaderService);
  private environment = inject(ENVIRONMENT_TOKEN);
  init(): Promise<void> {
    return this.thirdParty.loadExternalScriptBody(`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("consent", "default", {
          ad_storage: "denied",
          ad_user_data: "denied",
          ad_personalization: "denied",
          analytics_storage: "denied",
          functionality_storage: "denied",
          personalization_storage: "denied",
          security_storage: "granted",
          'region': ['UK', 'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'],
          wait_for_update: 2000,
        });
        gtag("consent", "default", {
          ad_storage: "granted",
          ad_user_data: "granted",
          ad_personalization: "granted",
          analytics_storage: "granted",
          functionality_storage: "granted",
          personalization_storage: "granted",
          security_storage: "granted",
          wait_for_update: 2000,
        });
        gtag("set", "ads_data_redaction", true);
        gtag("set", "url_passthrough", true);
      `);
  }
}
