import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncryptDecryptService {
  encryptMD5(value: string): string {
    return CryptoJS.MD5(value).toString();
  }
  encryptSHA1(value: string): string {
    return CryptoJS.SHA1(value).toString();
  }
}
