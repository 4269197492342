import { inject, Injectable } from '@angular/core';
import { ApiResponseModel, BaseCourseModel, PracticeLabsModel } from '@lms/shared/models';
import { BehaviorSubject, Observable, shareReplay, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PracticeLabsService {
  apiService = inject(ApiService);
  practiceLabData$$ = new BehaviorSubject<PracticeLabsModel[]>([]);
  practiceLabData$ = this.practiceLabData$$.asObservable();

  getData(): Observable<PracticeLabsModel[]> {
    return this.apiService.executeGet<ApiResponseModel<PracticeLabsModel[]>>('resource_center/case_study_listing').pipe(
      map(res => res.data),
      tap(res => this.practiceLabData$$.next(res)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  getEntity(uuid: string): Observable<BaseCourseModel> {
    return this.apiService.executeGet<ApiResponseModel<BaseCourseModel>>('resource_center/case_study/' + uuid).pipe(map(res => res.data));
  }

  updateCaseStudy(caseStudy: BaseCourseModel, bundleId: number): void {
    const data = this.practiceLabData$$.getValue();
    const bundleIndex = data.findIndex(item => item.bundleId === bundleId);
    const caseStudyIndex = data[bundleIndex].caseStudies.findIndex(item => item.uuid === caseStudy.uuid);
    data[bundleIndex].caseStudies[caseStudyIndex] = caseStudy;
    this.practiceLabData$$.next(data);
  }
}
