import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserModel } from '@lms/shared/models';
import { CurrentUserStorage } from '@lms/shared/storages';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appHasAccess]',
  standalone: false,
})
export class HasAccessDirective implements OnInit, OnDestroy {
  private isVisible = false;
  private permissions: string[] = [];
  private currentUser: UserModel | null = null;
  private subs: Subscription = new Subscription();

  constructor(
    private templateRef: TemplateRef<HasAccessDirective>,
    private viewContainer: ViewContainerRef,
    private userStorage: CurrentUserStorage,
  ) {}

  @Input()
  set appHasAccess(permissions: any) {
    this.permissions = permissions.flat();
    this.updateView();
  }

  ngOnInit(): void {
    this.subs.add(
      this.userStorage.getCurrentUserSubject().subscribe(user => {
        this.currentUser = user;
        this.updateView();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private updateView(): void {
    if (this.checkPermission()) {
      if (!this.isVisible) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isVisible = true;
      }
    } else {
      this.isVisible = false;
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    let hasPermission = false;

    if (this.currentUser?.permissionObjects) {
      for (const checkPermission of this.permissions) {
        const permissionFound = this.currentUser.permissionObjects.find(x => x === checkPermission);

        if (permissionFound) {
          hasPermission = true;
        }
      }
    }

    return hasPermission;
  }
}
