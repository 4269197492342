import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CurrentUserStorage } from '@lms/shared/storages';
import { AsyncPipe, NgIf } from '@angular/common';
import { FullImmersionBlockerDialogComponent } from '../full-immersion-blocker-dialog/full-immersion-blocker-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-upgrade-button',
  templateUrl: './upgrade-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgIf],
})
export class UpgradeButtonComponent {
  @Input() isSmall = false;
  url = '';

  constructor(
    public currentUser: CurrentUserStorage,
    private readonly dialog: MatDialog,
  ) {}

  public openDialog(): void {
    this.dialog.open(FullImmersionBlockerDialogComponent);
  }
}
