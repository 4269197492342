import { Pipe, PipeTransform } from '@angular/core';
import { TaxAmount } from '@lms/shared/models';

@Pipe({
  name: 'taxName',
  standalone: false,
})
export class TaxNamePipe implements PipeTransform {
  public transform(taxAmount: TaxAmount): string {
    return `${taxAmount.rate.label}`.trim();
  }
}
