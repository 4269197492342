import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TabItem } from '@lms/shared/models';
import { takeUntil } from 'rxjs/operators';
import { CustomBreakpointNames, LayoutService } from '@lms/shared/services/app-services';
import { DestroyableDirective } from '../destroyable/destroyable.directive';

@Directive({
  selector: '[appBaseTab]',
  standalone: false,
})
export class BaseTabDirective<T extends TabItem> extends DestroyableDirective implements AfterViewInit, OnChanges {
  @Input() tabs!: T[];

  @ViewChild('viewport') viewport!: ElementRef;
  @ViewChild('stripe') stripe!: ElementRef;
  @ViewChildren('tab') tabList!: QueryList<ElementRef>;

  public showArrows = false;
  public tabMap: any = {};
  public activeTab: number = 0;
  public tabsScrolledFromStart: number = 0;
  public offset = 0;
  public firstTab = false;
  public lastTab = false;
  public viewportWidth = 0;
  public stripeWidth = 0;

  public isMobile = false;

  constructor(
    public cdr: ChangeDetectorRef,
    public renderer: Renderer2,
    public layout: LayoutService,
  ) {
    super();
  }

  public ngOnChanges(): void {
    if (this.viewport) {
      setTimeout(() => this.calculateArrowStyles(), 500);
    }
  }

  public ngAfterViewInit(): void {
    this.layout
      .subscribeToLayoutChanges()
      .pipe(takeUntil(this._destroy$$))
      .subscribe(data => {
        setTimeout(() => this.calculateArrowStyles(), 500);
        this.isMobile = data.includes(CustomBreakpointNames.phone);
        if (!this.isMobile) {
          this.offset = 0;
          this.renderer.setStyle(this.stripe.nativeElement, 'transform', `translate(${-this.offset}px, 0)`);
        }
        this.cdr.markForCheck();
      });
  }

  public nextTab(): void {
    this.offset += this.tabMap[this.activeTab + 1] ? this.tabMap[this.activeTab + 1] : this.tabMap[this.tabs.length - 1];
    this.firstTab = this.offset <= 0;
    if (this.firstTab) {
      this.offset = 0;
      this.tabsScrolledFromStart = 0;
    }
    this.lastTab = this.stripeWidth <= this.viewportWidth + this.offset;
    this.renderer.setStyle(this.stripe.nativeElement, 'transform', `translate(${-this.offset}px, 0)`);
    this.cdr.markForCheck();
  }

  public prevTab(): void {
    this.offset -= this.tabMap[this.tabsScrolledFromStart - 1] ? this.tabMap[this.tabsScrolledFromStart - 1] : this.tabMap[0];
    this.tabsScrolledFromStart -= 1;
    this.firstTab = this.offset <= 0;
    if (this.firstTab) {
      this.offset = 0;
      this.tabsScrolledFromStart = 0;
    }
    this.lastTab = this.stripeWidth <= this.viewportWidth + this.offset;
    this.renderer.setStyle(this.stripe.nativeElement, 'transform', `translate(${-this.offset}px, 0)`);
    this.cdr.markForCheck();
  }

  public calculateArrowStyles(): void {
    this.viewportWidth = this.viewport.nativeElement.clientWidth;
    this.stripeWidth = this.stripe.nativeElement.clientWidth;
    this.showArrows = this.isMobile && this.viewportWidth - this.stripeWidth < 0;
    this.offset = 0;
    if (this.showArrows) {
      setTimeout(() => {
        this.tabList.forEach((tab: ElementRef, idx) => {
          const activeTab = [...tab.nativeElement.classList].some(el => el === 'active');
          if (activeTab) {
            this.activeTab = idx;
          }
          this.tabMap = { ...this.tabMap, [idx]: tab.nativeElement.clientWidth };
        });

        Object.keys(this.tabMap).forEach(key => {
          if (+key < +this.activeTab) {
            this.offset += this.tabMap[+key - 1] ? this.tabMap[+key - 1] : 0;
          }
        });

        this.firstTab = this.activeTab === 0 || this.offset === 0;
        this.lastTab = this.activeTab === this.tabs.length - 1;

        this.renderer.setStyle(this.stripe.nativeElement, 'transform', `translate(${-this.offset}px, 0)`);
        this.cdr.detectChanges();
      }, 500);
    }
  }
}
