import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSearch',
  standalone: false,
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: string, keyword: undefined | string | null): string {
    if (!keyword) {
      return value;
    }

    const regexForSpecialChars = /[-/^$*+?.()|[\]{}]/g;
    const keywordEscaped = keyword.replace(regexForSpecialChars, '\\$&');
    const regExp = new RegExp(`(${keywordEscaped})`, 'gi');

    return value.replace(regExp, '<em>$1</em>');
  }
}
