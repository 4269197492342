import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appFallbackSrc]',
  standalone: false,
})
export class FallbackSrcDirective {
  @Input() appFallbackSrc: string = '';
  private firedOnce!: boolean;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  @HostListener('error') onError(): void {
    if (this.firedOnce) {
      return;
    }
    this.firedOnce = true;
    const parentNode = this.el.nativeElement.parentNode;

    if (parentNode.tagName.toLowerCase() === 'picture') {
      for (const child of parentNode.children) {
        if (child.tagName.toLowerCase() === 'source') {
          parentNode.removeChild(child);
        }
      }
    }

    this.renderer.setAttribute(this.el.nativeElement, 'src', this.appFallbackSrc);
    this.renderer.setAttribute(this.el.nativeElement, 'alt', 'image');
    this.renderer.removeAttribute(this.el.nativeElement, 'srcset');
  }
}
