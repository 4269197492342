import { Pipe, PipeTransform } from '@angular/core';
import { PricingPlanModel } from '@lms/shared/models';
import { PricingPlanPricePipe } from './pricing-plan-price.pipe';

export const ZERO_PRICE = '$0';

@Pipe({
  name: 'pricingPlanTaxedPrice',
  standalone: false,
})
export class PricingPlanTaxedPricePipe extends PricingPlanPricePipe implements PipeTransform {
  public transform(pricingPlan: undefined | PricingPlanModel | null, customTemplate: undefined | string = undefined): string {
    if (
      !pricingPlan ||
      !pricingPlan.prices.regular ||
      !pricingPlan.prices.regular.totalPrice ||
      pricingPlan.prices.regular.totalPrice.amount === null
    ) {
      return ZERO_PRICE;
    }

    return this.print(pricingPlan, pricingPlan.prices.regular.totalPrice, customTemplate);
  }
}
