import { Pipe, PipeTransform } from '@angular/core';
import { PriceModel, PricingPlanEnum, PricingPlanModel } from '@lms/shared/models';
import { PricePipe } from './price.pipe';

export const FREE_PRICE = 'FREE';
export const ZERO_PRICE = '$0';

@Pipe({
  name: 'pricingPlanPrice',
  standalone: false,
})
export class PricingPlanPricePipe implements PipeTransform {
  constructor(protected pricePipe: PricePipe) {}

  public transform(pricingPlan: undefined | PricingPlanModel | null, customTemplate: undefined | string = undefined): string {
    if (!pricingPlan || !pricingPlan.price || pricingPlan.price.amount === null) {
      return ZERO_PRICE;
    }

    return this.print(pricingPlan, pricingPlan.price, customTemplate);
  }

  protected print(pricingPlan: PricingPlanModel, price: PriceModel, customTemplate: undefined | string = undefined): string {
    const amount = this.pricePipe.transform(price);

    switch (pricingPlan.plan) {
      case PricingPlanEnum.SUBSCRIPTION:
        if (customTemplate) {
          return customTemplate.replace('{price}', '' + amount).replace('{repeat_count}', '' + pricingPlan.intervalValue);
        }
        if (pricingPlan.intervalValue === 1) {
          return `${amount} each ${pricingPlan.intervalLength?.toLowerCase().slice(0, -1)}`;
        }
        return `${amount} each ${pricingPlan.intervalValue} ${pricingPlan.intervalLength?.toLowerCase()}`;
      case PricingPlanEnum.MONTHLY_SPLIT:
        if (customTemplate) {
          return customTemplate.replace('{price}', '' + amount).replace('{repeat_count}', '' + pricingPlan.intervalValue);
        }
        return `${amount} / month`;
      default:
        return `${amount}`;
    }
  }
}
