import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { catchError, EMPTY, Observable, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import {
  ApiResponseModel,
  CourseListingFilterModel,
  CoursesLearningNotesCountAbstractListingModel,
  CoursesLearningNotesCountListingModel,
  CoursesLearningNotesCountModel,
} from '@lms/shared/models';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LearningNotesService {
  constructor(private apiService: ApiService) {}

  listing(filter: CourseListingFilterModel): Observable<ApiResponseModel<CoursesLearningNotesCountAbstractListingModel>> {
    let params = new HttpParams()
      .set('pageNumber', filter.pageNumber?.toString() || '')
      .set('sort', filter.sort?.toString() || '')
      .set('pageSize', filter.pageSize?.toString() || '');
    if (typeof filter.programId !== 'undefined' && filter.programId !== null) {
      params = params.set('programId', filter.programId.toString() || '');
    }
    if (typeof filter.query !== 'undefined' && filter.query !== null && filter.query.length > 0) {
      params = params.set('query', filter.query.toString());
    }

    return this.apiService
      .executeGet<ApiResponseModel<CoursesLearningNotesCountListingModel>>('courses_learning_notes/listing', params)
      .pipe(
        map((response: ApiResponseModel<CoursesLearningNotesCountListingModel>) =>
          this.mapCoursesLearningNotesCountAbstractListingModel(response),
        ),
        catchError(() => {
          return of({
            data: {
              totalItems: [],
              itemsList: 0,
            },
          } as any);
        }),
      );
  }

  getEntity(): Observable<CoursesLearningNotesCountModel> {
    return EMPTY;
  }

  private mapCoursesLearningNotesCountAbstractListingModel(
    response: ApiResponseModel<CoursesLearningNotesCountListingModel>,
  ): ApiResponseModel<CoursesLearningNotesCountAbstractListingModel> {
    return {
      status: response.status,
      data: {
        ...response.data,
        itemsList: response.data.courses,
      },
    };
  }
}
