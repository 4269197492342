import { AdminFileModel } from '../admin-file';
import { ExamStatus } from '../course';
import { LearningItemSuccessStatusEnum } from '../learning-item';
import { LessonContentExamModel, LessonContentQuizModel } from '../lesson-content';
import { LessonContentTypeEnum } from '../lesson-content/enum';
import { SurveyStatusEnum } from '../survey/enum';
import { VideoModel } from '../video';
import { MetadataPlayer } from './meta-data.model';
import { BookmarkModel } from '../bookmark';

export interface CoursePlayerModel {
  actions: CourseActions;
  courseTree: CoursePlayerTree;
  selectedItem: LearningItem;
  nextItem: NextLearningItem;
  finalAction: FinalAction;
  course: CourseShortInfo;
  surveyStatus: SurveyStatusEnum;
  streakImageUrl?: string;
}

export interface CourseActions {
  getCertificate: boolean;
  completedByAdmin: boolean;
  showStreakMaintainedModal: boolean;
  showStreakMilestoneModal: boolean;
  canAskLearningAssistant: boolean;
  showLearningAssistant: boolean;
}

export interface CoursePlayerTree {
  progress: number;
  hasExam: boolean;
  freePreviewProgress: number;
  lastFreeLessonContentId: number;
  children: CoursePlayerTreeChild[];
}

export enum CoursePlayerTreeChildStatus {
  FAILED = 'FAILED',
  COMPLETE = 'COMPLETE',
  REQUIRED = 'REQUIRED',
  OPTIONAL = 'OPTIONAL',
  DRAFT = 'DRAFT',
}

export interface CoursePlayerTreeChild {
  id: number;
  learningItemId: number;
  type: LearningItemType | LearningType;
  title: string;
  selected: boolean;
  statistics: ChildrenStatistics;
  status: CoursePlayerTreeChildStatus;
  available: boolean;
  free: boolean;
  hasLearningNote: boolean;
  hideFromPaidOrB2B: boolean;
  endOfPreview: boolean;
  contentType: LessonContentTypeEnum;
  contentMetadata: MetadataPlayer[];
  children: CoursePlayerTreeChild[];
}

interface PositionModel {
  position: number;
  total: number;
}

export interface ChildrenStatistics {
  totalCompleted: number;
  requiredCompleted: number;
  required: number;
  total: number;
}

export enum LikeEnum {
  DISLIKE = 'DISLIKE',
  CANCEL = 'CANCEL',
  LIKE = 'LIKE',
}

export interface Link {
  title: string;
  url: string;
}

export interface LearningItem {
  available: boolean;
  children: LearningItem[];
  contentType: LessonContentTypeEnum;
  id: number;
  learningItemId: number;
  optional: boolean;
  prerequisite: boolean;
  selected: boolean;
  statistics: ChildrenStatistics;
  successStatus: LearningItemSuccessStatusEnum;
  title: string;
  chapterId: number;
  content: string;
  files: AdminFileModel[];
  attachments: AdminFileModel[];
  completed: boolean;
  lessonName?: string;
  fileUrl?: string;
  url?: string;
  video?: VideoModel;
  positionStatus?: PositionModel;
  free: boolean;
  hideFromPaidOrB2B: boolean;
  locked?: boolean;
  contentMetadata: MetadataPlayer[];
  doneThreshold: number;
  like: LikeEnum;
  downloadable: boolean;
  iframeHeight: number;
  exam: LessonContentExamModel;
  quiz: LessonContentQuizModel;
  learningNote: string;
  isLastItem: boolean;
  isPaid: boolean;
  showCelebrationPage: boolean;
  isDescriptionAboveMultimedia: boolean;
  links: Link[];
}

export type LearningItemType = 'LESSON_CONTENT' | 'LESSON' | 'CHAPTER';
export type LearningType = 'ELECTIVE' | 'PREREQUISITE' | 'REQUIRED';

export interface CourseShortInfo {
  id: string;
  name: string;
  hasExam: boolean;
  examStatus: ExamStatus;
  type: LearningItemType | LearningType;
  // temp params
  examLearningItemId: string;
  url: string;
  courseLength: string;
  isFreePreview: boolean;
  uuid: string;
  savedCourse: BookmarkModel | null;
  note: string;
}

export interface NextLearningItem {
  id: number;
  learningItemId: number;
  title: string;
}

export enum DestinationEnum {
  SUBMIT_SURVEY = 'SUBMIT_SURVEY',
  SUBMIT_REVIEW = 'SUBMIT_REVIEW',
  COURSE_COMPLETED = 'COURSE_COMPLETED',
}

export interface FinalAction {
  continueToFinalAvailable: boolean;
  gotoFinal: boolean;
  finalDestination: DestinationEnum;
}
