import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyIfTooLong',
  standalone: false,
})
export class EmptyIfTooLongPipe implements PipeTransform {
  transform(value: string, maxChars: number, spaceBefore: boolean = true): string | null {
    if (!value || value.length > maxChars) {
      return '';
    }

    return spaceBefore ? ` ${value}` : value;
  }
}
