import { Inject, Injectable } from '@angular/core';
import { ThirdPartyLoaderService } from './third-party-loader.service';
import { ApiResponseModel, Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';
import { ApiService } from '@lms/shared/services/api-services';
import { Observable } from 'rxjs';
import { KlaviyoProfileModel } from '@lms/shared/models';

declare const klaviyo: any;

@Injectable({ providedIn: 'root' })
export class KlaviyoService {
  klaviyoObjectInitialization: string = `
      !function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();
  `;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) public environment: Environment,
    private thirdPartyLoaderService: ThirdPartyLoaderService,
    private apiService: ApiService,
  ) {}

  loginNotify(email: string): void {
    this.thirdPartyLoaderService
      .loadExternalScript('//static.klaviyo.com/onsite/js/klaviyo.js?company_id=' + this.environment.klaviyoPublicKey)
      .then(() => {
        const styleElement = document.createElement('script');
        styleElement.text = this.klaviyoObjectInitialization;
        styleElement.type = 'text/javascript';
        document.head.appendChild(styleElement);

        klaviyo.identify({
          $email: email,
          'Last Login Date': new Date().toISOString(),
        });
      });
  }

  getProfile(id: string): Observable<ApiResponseModel<KlaviyoProfileModel>> {
    return this.apiService.executeGet(`klaviyo/profile/${id}`);
  }
}
