import { Pipe, PipeTransform } from '@angular/core';

type mimeTypesOptions = {
  [key: string]: string;
};

const MIME_TYPE_ICON_MAP: mimeTypesOptions = {
  'application/pdf': 'fa-file-pdf',
  'image/jpeg': 'fa-file-image',
  'image/png': 'fa-file-image',
  'image/gif': 'fa-file-image',
  'application/octet-stream': 'fa-file',
  'text/plain': 'fa-file',
  'application/zip': 'fa-file-archive',
  'audio/mpeg': 'fa-file-audio',
  'video/mp4': 'fa-file-video',
  'video/x-msvideo': 'fa-file-video',
  'video/avi': 'fa-file-video',
  'video/mpeg': 'fa-file-video',
  'text/csv': 'fa-file-csv',
  'application/msword': 'fa-file-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa-file-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'fa-file-word',
  'application/vnd.ms-word.document.macroEnabled': 'fa-file-word',
  'application/vnd.ms-word.template.macroEnabled': 'fa-file-word',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'fa-file-excel',
  'application/vnd.ms-excel': 'fa-file-excel',
  'application/msexcel': 'fa-file-excel',
  'application/x-msexcel': 'fa-file-excel',
  'application/x-ms-excel': 'fa-file-excel',
  'application/x-excel': 'fa-file-excel',
  'application/x-dos_ms_excel': 'fa-file-excel',
  'application/xls': 'fa-file-excel',
  'application/x-xls': 'fa-file-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'fa-file-excel',
  'application/vnd.ms-excel.sheet.macroEnabled': 'fa-file-excel',
  'application/vnd.ms-excel.template.macroEnabled': 'fa-file-excel',
  'application/vnd.ms-excel.addin.macroEnabled': 'fa-file-excel',
  'application/vnd.ms-excel.sheet.binary.macroEnabled': 'fa-file-excel',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'fa-file-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.template': 'fa-file-powerpoint',
  'application/vnd.ms-powerpoint': 'fa-file-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'fa-file-powerpoint',
  'application/vnd.ms-powerpoint.addin.macroEnabled': 'fa-file-powerpoint',
  'application/vnd.ms-powerpoint.presentation.macroEnabled': 'fa-file-powerpoint',
  'application/vnd.ms-powerpoint.template.macroEnabled': 'fa-file-powerpoint',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled': 'fa-file-powerpoint',
};

@Pipe({
  name: 'fileTypeIcon',
  standalone: false,
})
export class FileTypeIconPipe implements PipeTransform {
  transform(mimeType: string | null): string {
    if (mimeType && MIME_TYPE_ICON_MAP[mimeType]) {
      return MIME_TYPE_ICON_MAP[mimeType];
    }
    return '';
  }
}
