import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownItem } from '@lms/shared/models';
import { noop } from 'rxjs';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DropdownSelectComponent implements ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() clearable: boolean = true;
  @Input() disabled: boolean = false;
  @Input() items: DropdownItem[] = [];

  currentValue: string | null = null;
  public onChange: (_: string | null) => void = () => noop();
  public onTouch: () => void = () => noop();

  constructor(private cdr: ChangeDetectorRef) {}

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(value: string | null): void {
    this.currentValue = value;
    this.cdr.markForCheck();
  }

  get value(): string | null {
    return this.currentValue;
  }

  set value(value: string | null) {
    if (this.currentValue !== value) {
      this.currentValue = value;
      this.onChange(this.currentValue);
      this.cdr.markForCheck();
    }
  }
}
