import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural',
  standalone: false,
})
export class PluralPipe implements PipeTransform {
  transform(value: unknown, numberToSolve: number): unknown {
    if (numberToSolve === 1) {
      return value;
    }
    return value + 's';
  }
}
