import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetter',
  standalone: false,
})
export class FirstLetterPipe implements PipeTransform {
  transform(value: string | undefined): unknown {
    return value && value[0] ? value[0].toUpperCase() : '';
  }
}
