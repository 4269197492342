import { ChangeDetectorRef, ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { CoursePlayerTreeChildStatus } from '@lms/shared/models';
import { CurrentUserStorage } from '@lms/shared/storages';

@Component({
  selector: 'app-course-tree-node-status',
  templateUrl: './course-tree-node-status.component.html',
  styleUrls: ['./course-tree-node-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CourseTreeNodeStatusComponent implements OnChanges {
  @Input() nodeStatus: string = CoursePlayerTreeChildStatus.REQUIRED;
  @Input() showTooltip: boolean = true;
  @Input() lockIconActive: boolean = false;
  @Input() isFreePreview: boolean | undefined;
  @Input() endOfPreview: boolean = false;
  @Input() isLesson: boolean = false;
  coursePlayerTreeChildStatus = CoursePlayerTreeChildStatus;

  icon: string = '';
  iconClass: Record<string, boolean> = {
    passed: false,
    failed: false,
    incomplete: false,
    optional: false,
    passed_lesson: false,
    failed_lesson: false,
    incomplete_lesson: false,
    optional_lesson: false,
    locked: false,
    'end-of-preview': false,
  };

  constructor(
    public currentUserStorage: CurrentUserStorage,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    this.setIcon();
  }

  setIcon(): void {
    if (!this.isLesson) {
      this.icon = 'course_complete';
    }
    this.iconClass = {
      passed: this.nodeStatus === CoursePlayerTreeChildStatus.COMPLETE && !this.lockIconActive && !this.endOfPreview && !this.isLesson,
      failed: this.nodeStatus === CoursePlayerTreeChildStatus.FAILED && !this.lockIconActive && !this.endOfPreview && !this.isLesson,
      incomplete: this.nodeStatus === CoursePlayerTreeChildStatus.REQUIRED && !this.lockIconActive && !this.endOfPreview && !this.isLesson,
      optional:
        this.nodeStatus === (CoursePlayerTreeChildStatus.OPTIONAL || this.nodeStatus === CoursePlayerTreeChildStatus.DRAFT) &&
        !this.lockIconActive &&
        !this.endOfPreview &&
        !this.isLesson,
      passed_lesson:
        this.nodeStatus === CoursePlayerTreeChildStatus.COMPLETE && !this.lockIconActive && !this.endOfPreview && this.isLesson,
      failed_lesson: this.nodeStatus === CoursePlayerTreeChildStatus.FAILED && !this.lockIconActive && !this.endOfPreview && this.isLesson,
      incomplete_lesson:
        this.nodeStatus === CoursePlayerTreeChildStatus.REQUIRED && !this.lockIconActive && !this.endOfPreview && this.isLesson,
      optional_lesson:
        this.nodeStatus === (CoursePlayerTreeChildStatus.OPTIONAL || this.nodeStatus === CoursePlayerTreeChildStatus.DRAFT) &&
        !this.lockIconActive &&
        !this.endOfPreview &&
        this.isLesson,
      locked: this.lockIconActive,
      'end-of-preview': this.endOfPreview,
    };
    if (
      (this.nodeStatus === CoursePlayerTreeChildStatus.OPTIONAL || this.nodeStatus === CoursePlayerTreeChildStatus.DRAFT) &&
      !this.isLesson
    ) {
      this.icon = 'course_optional';
    }
    if (this.isLesson) {
      this.icon = 'checkbox_new';
    }
    if (this.endOfPreview) {
      this.icon = 'triangle-alert';
    }
    this.cdr.markForCheck();
  }

  clickTooltipLink(): void {
    window
      ?.open(
        !this.currentUserStorage.isUserSubscriber() &&
          !this.currentUserStorage.isUserFullImmersion() &&
          !this.currentUserStorage.getCurrentUser()?.enrolledBundles?.length
          ? 'https://corporatefinanceinstitute.com/upgrade/'
          : 'https://corporatefinanceinstitute.com/all-access-upgrade/',
        '_blank',
      )
      ?.focus();
  }
}
