import {
  ApiResponseModel,
  MarketingResourceListingAbstractListingModel,
  MarketingResourceListingModel,
  MarketingResourceModel,
} from '@lms/shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { inject } from '@angular/core';

export class MarketingResourceBaseService {
  protected apiService = inject(ApiService);
  protected mapMarketingResourceListingModel(
    response: ApiResponseModel<MarketingResourceListingModel>,
  ): ApiResponseModel<MarketingResourceListingAbstractListingModel> {
    return {
      status: { code: '', desc: '', warnings: [] },
      data: {
        ...response.data,
        itemsList: response.data.items,
        totalItems: response.data.pagination.totalItems,
      },
    };
  }

  getEntity(id: string): Observable<MarketingResourceModel> {
    return this.apiService
      .executeGet<ApiResponseModel<MarketingResourceModel>>('resource_center/marketing_resource/' + id)
      .pipe(map(res => res.data));
  }
}
