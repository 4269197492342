import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Renderer2 } from '@angular/core';
import { LayoutService } from '@lms/shared/services/app-services';
import { BaseTabDirective } from '../base-tab/base-tab.directive';
import { TabItem, UserEventClass } from '@lms/shared/models';
import { LogService } from '@lms/shared/services/api-services';
import { take } from 'rxjs/operators';
import { CurrentUserStorage } from '@lms/shared/storages';

@Component({
  selector: 'app-tabs-routed',
  templateUrl: './tabs-routed.component.html',
  styleUrls: ['./tabs-routed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TabsRoutedComponent extends BaseTabDirective<TabItem> {
  @Input() exactRouteActive = false;
  @Input() withLogs: boolean = false;

  constructor(
    public cdr: ChangeDetectorRef,
    public renderer: Renderer2,
    public layout: LayoutService,
    private logService: LogService,
    private userStorage: CurrentUserStorage,
  ) {
    super(cdr, renderer, layout);
  }

  public logTabClick(e: Event, logName?: string, eventName?: string): void {
    if (!this.withLogs) return;
    const link = e.currentTarget as HTMLAnchorElement;
    if (this.userStorage.isUserSubscriber() && logName) {
      const pathname = window.location.origin + window.location.pathname;
      const linkDest = link.href ? link.href : pathname;
      const pricingPlan = this.userStorage.isUserFullImmersion() ? 'Full-Immersion' : 'Self-Study';
      const linkLocation = 'page_tabs';
      const linkLogObj = new UserEventClass(eventName, logName, linkLocation, pathname, linkDest, pricingPlan);

      this.logService.linkClickLogEvent(linkLogObj).pipe(take(1)).subscribe();
    }
  }
}
