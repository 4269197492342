import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdminCourseLessonNoteModel, CourseWithLessonNotesModel } from '@lms/shared/models';

@Injectable({ providedIn: 'root' })
export class LessonNoteStoreService {
  private readonly _lessonNote$ = new BehaviorSubject<AdminCourseLessonNoteModel | null>(null);
  private readonly _courseWithLessonNotes$ = new BehaviorSubject<CourseWithLessonNotesModel | null>(null);
  readonly courseWithLessonNotes$ = this._courseWithLessonNotes$.asObservable();

  setLessonNote(lessonNote: AdminCourseLessonNoteModel | null): void {
    this._lessonNote$.next(lessonNote);
  }

  setCourseWithLessonNotes(courseModel: CourseWithLessonNotesModel): void {
    this._courseWithLessonNotes$.next(courseModel);
  }
}
