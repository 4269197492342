import { inject, Injectable } from '@angular/core';
import {
  ApiResponseModel,
  MarketingResourceListingAbstractListingModel,
  MarketingResourceListingModel,
  ListingFilter,
} from '@lms/shared/models';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { MarketingResourceBaseService } from './marketing-resource.service';

@Injectable({ providedIn: 'root' })
export class ArticleService extends MarketingResourceBaseService {
  apiService = inject(ApiService);

  listing(filter: ListingFilter): Observable<ApiResponseModel<MarketingResourceListingAbstractListingModel>> {
    let params = new HttpParams().set('pageNumber', filter.pageNumber?.toString() || '').set('pageSize', filter.pageSize?.toString());

    if (typeof filter.query !== 'undefined' && filter.query !== null && filter.query.length > 0) {
      params = params.set('query', filter.query.toString());
    }

    if (typeof filter.category !== 'undefined' && filter.category !== null) {
      params = params.set('categoryId', filter.category.toString());
    }

    return this.apiService
      .executeGet<ApiResponseModel<MarketingResourceListingModel>>('resource_center/articles_listing', params)
      .pipe(map((response: ApiResponseModel<MarketingResourceListingModel>) => this.mapMarketingResourceListingModel(response)));
  }
}
