import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CourseLessonNoteService } from '@lms/shared/services/api-services';
import { Observable } from 'rxjs';
import { CourseWithLessonNotesModel } from '@lms/shared/models';
import { tap } from 'rxjs/operators';
import { LessonNoteStoreService } from '../lesson-note-store.service';

@Injectable()
export class NotesDetailsResolver {
  constructor(
    private courseLessonNoteService: CourseLessonNoteService,
    private lessonNoteStore: LessonNoteStoreService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<CourseWithLessonNotesModel> {
    const courseUrl = route.paramMap.get('courseUrl') || '';

    return this.courseLessonNoteService.getCourseLessonNotes(courseUrl).pipe(
      tap((courseModel: CourseWithLessonNotesModel) => {
        this.lessonNoteStore.setCourseWithLessonNotes(courseModel);
      }),
    );
  }
}
