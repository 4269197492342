import { inject, Injectable } from '@angular/core';
import {
  ApiResponseModel,
  ListingFilter,
  MarketingResourceListingAbstractListingModel,
  MarketingResourceListingModel,
  MarketingResourceModel,
} from '@lms/shared/models';
import { Observable, EMPTY } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CareerResourcesService {
  private readonly apiService = inject(ApiService);

  listing(filter: ListingFilter): Observable<ApiResponseModel<MarketingResourceListingAbstractListingModel>> {
    const params = new HttpParams().set('resourceIds', filter.resourceIds?.join(',') || '');

    return this.apiService
      .executeGet<ApiResponseModel<MarketingResourceListingModel>>('resource_center/career_resources_listing', params)
      .pipe(map((response: ApiResponseModel<MarketingResourceListingModel>) => this.mapMarketingResourcesListingModel(response)));
  }

  getEntity(id: string): Observable<MarketingResourceModel> {
    return EMPTY;
  }

  private mapMarketingResourcesListingModel(
    response: ApiResponseModel<MarketingResourceListingModel>,
  ): ApiResponseModel<MarketingResourceListingAbstractListingModel> {
    return {
      status: { code: '', desc: '', warnings: [] },
      data: {
        ...response.data,
        itemsList: response.data.items,
        totalItems: response.data.items.length,
      },
    };
  }
}
