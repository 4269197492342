import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { debounceTime, fromEvent, Subscription } from 'rxjs';

const AUTO_LOAD_TIMEOUT = 500;
const DEBOUNCE_SCROLL = 200;

@Directive({
  selector: '[appInfiniteScroll]',
  standalone: false,
})
export class InfiniteScrollDirective implements OnChanges, OnInit, OnDestroy {
  @Input() resetData = false;
  @Input() dataLength: number | undefined | null = 0;
  @Output() public loadMore = new EventEmitter();
  el!: Element;
  loadMoreHistory: Record<number, number>;

  private _counter = 1;
  private subscription = new Subscription();

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;

    this.loadMoreHistory = {
      [this._counter]: this.el.getBoundingClientRect().height,
    };
    this.resetCounter();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      fromEvent(window, 'scroll', { passive: true })
        .pipe(debounceTime(DEBOUNCE_SCROLL))
        .subscribe(() => {
          this.handleScroll();
        }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataLength?.currentValue) {
      setTimeout(() => this.handleScroll(), AUTO_LOAD_TIMEOUT);
    }
    if (changes.resetData?.currentValue) {
      requestAnimationFrame(() => this.handleScroll());
    }
  }

  getPosition(): DOMRect {
    return (this.el as Element).getBoundingClientRect();
  }

  resetCounter(): void {
    this._counter = 1;
  }

  private handleScroll(): void {
    const bounds = this.getPosition();
    const actualHeight = bounds.height;
    const percentViewed = Math.floor(((window.innerHeight - this.getPosition().top) * 100) / actualHeight);

    // in some cases percentViewed can equal 100 , and it caused bug that scroll event was not emitted. We use 98 just for insurance, it should be >= 100, but in order to avoid possible bug we use 98
    if (percentViewed > 98) {
      if (this.resetData) {
        this._counter = 1;
        this.loadMore.emit(this._counter);
        this.loadMoreHistory[this._counter] = actualHeight;
      } else if (this.loadMoreHistory[this._counter] !== actualHeight) {
        this.loadMore.emit(this._counter + 1);
        this.loadMoreHistory[this._counter] = actualHeight;
      }
    }
  }
}
