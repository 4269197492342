import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Host,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { debounceTime, EMPTY, merge, Observable, Subscription } from 'rxjs';
import { ControlErrorComponent } from '../control-error/control-error.component';
import { FORM_ERRORS } from '../form-errors';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { FormSubmitDirective } from './form-submit.directive';

const FORBIDDEN_TYPE_TO_WRAP = ['checkbox', 'radio'];
const CONTROL_ERROR_CLASS = 'control-has-error';

@Directive({
  selector: '[formControl], [formControlName]:not([controlErrorsIgnore]), [ngModel]',
  standalone: false,
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
  ref!: ComponentRef<ControlErrorComponent>;
  container: ViewContainerRef;
  submit$: Observable<Event | unknown>;
  subscription: Subscription = new Subscription();

  constructor(
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    @Optional() controlErrorContainer: ControlErrorContainerDirective,
    @Inject(FORM_ERRORS) private errors: Record<string, any>,
    @Optional() @Host() private form: FormSubmitDirective,
    private controlDir: NgControl,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {
    this.container = vcr;
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  get control(): AbstractControl | null {
    return this.controlDir.control;
  }

  ngOnInit(): void {
    if (this.control) {
      // wrap control in order to display error icon on control
      if (FORBIDDEN_TYPE_TO_WRAP.indexOf(this.elementRef.nativeElement.type) === -1) {
        this.wrapControl();
      }

      this.subscription.add(
        merge(this.submit$, this.control.statusChanges, this.control.valueChanges)
          .pipe(debounceTime(100))
          .subscribe(() => {
            // display backend validation errors
            const controlServerErrors = this.control?.errors?.serverError ? this.control?.errors?.serverError[0] : null;
            if (controlServerErrors) {
              this.renderError(controlServerErrors);
            } else if (this.ref) {
              this.renderError(null);
            }

            // display frontend validation errors
            const controlErrors = this.control?.errors;
            if (controlErrors && !controlServerErrors) {
              const firstKey = Object.keys(controlErrors)[0];
              const getError = this.errors[firstKey];
              const text = firstKey ? getError(controlErrors[firstKey]) : '';
              this.renderError(text);
            } else if (this.ref && !controlServerErrors) {
              this.renderError(null);
            }
            this.cdr.markForCheck();
          }),
      );

      this.subscription.add(this.control.statusChanges.subscribe());
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  wrapControl(): void {
    const div = this.renderer.createElement('div');
    this.renderer.insertBefore(this.elementRef.nativeElement.parentElement, div, this.elementRef.nativeElement);
    this.renderer.appendChild(div, this.elementRef.nativeElement);
  }

  renderError(text: string | null): void {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
      this.ref = this.container.createComponent(factory);
    }
    this.ref.instance.text = text;

    const parentEl = this.elementRef.nativeElement.parentElement;
    if (parentEl) {
      this.renderer.removeClass(parentEl, CONTROL_ERROR_CLASS);
      if (text) {
        this.renderer.addClass(parentEl, CONTROL_ERROR_CLASS);
      }
    }
  }
}
