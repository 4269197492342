import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { DestroyableDirective } from '../destroyable/destroyable.directive';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-view-wysiwyg',
  templateUrl: './view-wysiwyg.component.html',
  styleUrls: ['./view-wysiwyg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ViewWysiwygComponent extends DestroyableDirective implements OnInit, OnChanges {
  @Input() html: string | null = '';
  @Input() collapsible!: boolean;
  @ViewChild('jodit', { static: true })
  viewport!: ElementRef;

  expanded: boolean = false;

  private contentHeight: number = -1;

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadExternalStyles('//cdnjs.cloudflare.com/ajax/libs/jodit/3.4.25/jodit.min.css');

    if (this.collapsible) {
      fromEvent(window, 'resize')
        .pipe(debounceTime(250), takeUntil(this._destroy$$))
        .subscribe(() => this.calcHeight());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.collapsible && changes.html) {
      requestAnimationFrame(() => this.calcHeight());
    }
  }

  private loadExternalStyles(styleUrl: string): Promise<any> {
    return new Promise(resolve => {
      const styleElement = document.createElement('link');
      styleElement.type = 'text/css';
      styleElement.rel = 'stylesheet';
      styleElement.href = styleUrl;
      styleElement.onload = resolve;
      document.head.appendChild(styleElement);
    });
  }

  private calcHeight(): void {
    this.contentHeight = this.el.nativeElement.children[0].children[0].clientHeight || 0;
    this.contentHeight ? (this.contentHeight > 200 ? (this.collapsible = true) : (this.collapsible = false)) : (this.collapsible = false);
    this.cdr.markForCheck();
  }
}
