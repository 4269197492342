import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import {
  ApiResponseModel,
  CourseListingAbstractListingModel,
  CourseListingFilterModel,
  CourseListingModel,
  CourseModel,
  CourseShowModel,
  Environment,
  ENVIRONMENT_TOKEN,
} from '@lms/shared/models';
import { Router, UrlSerializer } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StudentCourseService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private urlSerializer: UrlSerializer,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
  ) {}

  listing(filter: CourseListingFilterModel): Observable<ApiResponseModel<CourseListingAbstractListingModel>> {
    let params = new HttpParams()
      .set('pageNumber', filter.pageNumber?.toString() || '')
      .set('sort', filter.sort?.toString() || '')
      .set('pageSize', filter.pageSize?.toString() || '');
    if (typeof filter.query !== 'undefined' && filter.query !== null && filter.query.length > 0) {
      params = params.set('query', filter.query.toString());
    }
    if (typeof filter.category !== 'undefined' && filter.category !== null) {
      params = params.set('category', filter.category.toString());
    }
    if (typeof filter.level !== 'undefined' && filter.level !== null) {
      params = params.set('level', filter.level?.toString());
    }
    if (typeof filter.learningStatus !== 'undefined' && filter.learningStatus !== null) {
      params = params.set('learningStatus', filter.learningStatus?.toString());
    }
    if (typeof filter.price !== 'undefined' && filter.price !== null) {
      params = params.set('price', filter.price?.toString());
    }
    if (typeof filter.programId !== 'undefined' && filter.programId !== null) {
      params = params.set('programId', filter.programId?.toString());
    }
    if (typeof filter.progress !== 'undefined' && filter.progress !== null) {
      params = params.set('progress', filter.progress?.toString());
    }
    if (typeof filter.cpeAccredited !== 'undefined' && filter.cpeAccredited !== null) {
      params = params.set('cpeCredit', filter.cpeAccredited);
    }
    if (typeof filter.financeDomainId !== 'undefined' && filter.financeDomainId !== null) {
      params = params.set('financeDomainId', filter.financeDomainId);
    }

    return this.apiService.executeGet<ApiResponseModel<CourseListingModel>>('courses/student_listing', params).pipe(
      map((response: ApiResponseModel<CourseListingModel>) => this.mapCourseHistoryListingModel(response)),
      catchError(() => {
        return of({
          data: {
            courses: [],
            itemsList: 0,
          },
        } as any);
      }),
    );
  }

  getEntity(id: string): Observable<CourseModel> {
    const params = new HttpParams().set('url', id);
    return this.apiService.executeGet<ApiResponseModel<CourseShowModel>>('courses/show', params).pipe(map(res => res.data.course));
  }

  private mapCourseHistoryListingModel(
    response: ApiResponseModel<CourseListingModel>,
  ): ApiResponseModel<CourseListingAbstractListingModel> {
    return {
      status: response.status,
      data: {
        ...response.data,
        itemsList: response.data.courses,
      },
    };
  }
}
