import { Pipe, PipeTransform } from '@angular/core';
import { MarketingResourceType } from '@lms/shared/models';

const RESOURCE_LINK = {
  ARTICLE: 'Read Article',
  PODCAST: 'Listen to Podcast',
  WEBINAR: 'Watch Webinar',
};

@Pipe({
  name: 'resourceLinkType',
})
export class ResourceLinkTypePipe implements PipeTransform {
  transform(value: MarketingResourceType): string {
    if (!value) return '';
    return RESOURCE_LINK[value] || 'Preview';
  }
}
