import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-insight-box',
  templateUrl: './insight-box.component.html',
  styleUrls: ['./insight-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InsightBoxComponent {
  @Input() topText: string | undefined;
  @Input() bottomText: string | undefined;
}
