import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import {
  AdminCourseModel,
  ApiResponseModel,
  CartProductModel,
  EnrollResponseModel,
  PaymentProviderEnum,
  PricingContextEnum,
  PricingPlanListingModel,
  PricingPlanModel,
  PricingPlanShowModel,
} from '@lms/shared/models';
import { Observable, pluck } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PricingPlanService {
  constructor(private apiService: ApiService) {}

  getAll(courseId: number, bundleId: number, pricingContext: PricingContextEnum): Observable<ApiResponseModel<PricingPlanListingModel>> {
    let params = new HttpParams().set('type', pricingContext.toString());
    if (pricingContext === PricingContextEnum.COURSE) {
      params = params.set('course', courseId.toString());
    } else {
      params = params.set('bundle', bundleId.toString());
    }
    return this.apiService.executeGet<ApiResponseModel<PricingPlanListingModel>>('admin/pricing_plans/listing', params);
  }

  updatePricingPlan(pricingPlan: PricingPlanModel, form?: AbstractControl): Observable<ApiResponseModel<PricingPlanModel>> {
    return this.apiService.executePost<ApiResponseModel<PricingPlanModel>>(
      'admin/pricing_plans/update',
      {
        pricingPlanId: pricingPlan.id,
        pricingPlan,
      },
      false,
      form,
    );
  }

  createPricingPlan(pricingPlan: PricingPlanModel, form?: AbstractControl): Observable<ApiResponseModel<PricingPlanShowModel>> {
    return this.apiService.executePost<ApiResponseModel<PricingPlanShowModel>>(
      'admin/pricing_plans/create',
      {
        pricingPlan,
        courseId: pricingPlan.courseId,
        bundleId: pricingPlan.bundleId,
        type: pricingPlan.type,
      },
      false,
      form,
    );
  }

  makePrimary(
    courseId: number,
    bundleId: number,
    type: PricingContextEnum,
    pricingPlanId: number,
  ): Observable<ApiResponseModel<AdminCourseModel>> {
    return this.apiService.executePost<ApiResponseModel<AdminCourseModel>>('admin/pricing_plans/choose_primary', {
      courseId,
      bundleId,
      type,
      pricingPlanId,
    });
  }

  deletePricingPlan(
    courseId: number,
    bundleId: number,
    pricingPlanId: number,
    type: PricingContextEnum,
  ): Observable<ApiResponseModel<null>> {
    let params = new HttpParams().set('pricingPlanId', pricingPlanId.toString()).set('type', type.toString());
    if (courseId) {
      params = params.set('courseId', courseId.toString());
    }
    if (bundleId) {
      params = params.set('bundleId', bundleId.toString());
    }
    return this.apiService.executeDelete<ApiResponseModel<null>>('admin/pricing_plans/delete', params);
  }

  enroll(
    pricingPlanId: number,
    paymentMethodId: number | null,
    paymentProvider: PaymentProviderEnum | string | null,
    paypalOrderId: string | null = null,
    couponCode: string | null = null,
    migrate = false,
    referrer?: string,
    orderToReactivateUuid: string | null = null,
  ): Observable<ApiResponseModel<EnrollResponseModel>> {
    return this.apiService.executePost<ApiResponseModel<EnrollResponseModel>>('pricing_plans/enroll', {
      pricingPlanId,
      paypalOrderId,
      paymentMethodId,
      paymentProvider,
      couponCode,
      migrate,
      referrer: migrate ? referrer : null,
      ...(orderToReactivateUuid && { orderToReactivateUuid }),
    });
  }

  getOrder(uuid: string): Observable<EnrollResponseModel> {
    const params = new HttpParams().set('uuid', uuid.toString());
    return this.apiService
      .executeGet<ApiResponseModel<EnrollResponseModel>>('pricing_plans/enroll_attempt', params)
      .pipe(map(res => res.data));
  }

  getCertificateFeeOrder(uuid: string): Observable<EnrollResponseModel> {
    const params = new HttpParams().set('uuid', uuid.toString());
    return this.apiService
      .executeGet<ApiResponseModel<EnrollResponseModel>>('pricing_plans/process_fee_attempt', params)
      .pipe(map(res => res.data));
  }

  fee(
    programId: number,
    pricingPlanId: number,
    paymentMethodId: number | null,
    paymentProvider: PaymentProviderEnum | null,
    paypalOrderId: string | null = null,
    couponCode: string | null = null,
    userCertificateRequestIds: Array<number> | null = null,
  ): Observable<EnrollResponseModel> {
    return this.apiService
      .executePost<ApiResponseModel<EnrollResponseModel>>('pricing_plans/process_fee', {
        programId,
        pricingPlanId,
        paypalOrderId,
        paymentMethodId,
        paymentProvider,
        couponCode,
        userCertificateRequestIds: userCertificateRequestIds ?? [],
      })
      .pipe(map(res => res.data));
  }

  getCertificatesProducts(userCertificateRequestIds: Array<number>): Observable<Array<CartProductModel>> {
    const params = new HttpParams({ fromObject: { 'rids[]': userCertificateRequestIds } });
    return this.apiService
      .executeGet<ApiResponseModel<{ products: Array<CartProductModel> }>>('certificates/pricing_plans', params)
      .pipe(pluck('data', 'products'));
  }
}
