import { Pipe, PipeTransform } from '@angular/core';
import { PricingPlanEnum, PricingPlanModel } from '@lms/shared/models';
import { PricePipe } from './price.pipe';

export const ZERO_PRICE = '$0';

@Pipe({
  name: 'pricingPlanFirstPaymentPrice',
  standalone: false,
})
export class PricingPlanFirstPaymentPricePipe implements PipeTransform {
  constructor(protected pricePipe: PricePipe) {}
  public transform(pricingPlan: undefined | PricingPlanModel | null): string {
    if (!pricingPlan) {
      return ZERO_PRICE;
    }

    const price = pricingPlan.prices?.custom?.totalPrice || pricingPlan.prices?.discounted?.totalPrice;
    const amount = this.pricePipe.transform(price);

    switch (pricingPlan.plan) {
      case PricingPlanEnum.SUBSCRIPTION:
        if (pricingPlan.intervalValue === 1) {
          return `${amount} for first ${pricingPlan.intervalLength?.toLowerCase().slice(0, -1)}`;
        }
        return `${amount} for first ${pricingPlan.intervalValue} ${pricingPlan.intervalLength?.toLowerCase()}`;
      case PricingPlanEnum.MONTHLY_SPLIT:
        return `${amount} / month`;
      default:
        return `${amount}`;
    }
  }
}
