import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appDestroyable]',
  standalone: false,
})
export class DestroyableDirective implements OnDestroy {
  protected _destroy$$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this._destroy$$.next();
    this._destroy$$.complete();
  }
}
