import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  template: `<p class="error" [class.hide]="hide">
    <app-svg-icon icon="close"></app-svg-icon> <span [innerHTML]="errorText | safeHtml"></span>
  </p>`,
  styleUrls: ['./control-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ControlErrorComponent {
  public errorText!: string;
  public hide = true;

  @Input() public set text(value: string | null) {
    if (value !== this.errorText) {
      this.errorText = value || '';
      this.hide = !value;
      this.cdr.detectChanges();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
